import React from 'react';

const OutdoorFacilitySection = () => {
  return (
    <section id="outdoor-facility">
      Out door facility
    </section>
  );
}

export default OutdoorFacilitySection;
