import React from 'react';

const IndoorFacilitySection = () => {
  return (
    <section id="indoor-facility">
      In door facility
    </section>
  );
}

export default IndoorFacilitySection;
