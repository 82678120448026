import React from 'react'
import "../../components/styles/sections/News/News.css";
import { SectionHeading } from '../styles/common/SectionHeading.styled';

// import One from "../../Images/homeImages/1.png";
// import Two from "../../Images/homeImages/2.png";
// import Three from "../../Images/homeImages/3.png";
// import Four from "../../Images/homeImages/4.png";
// import Five from "../../Images/homeImages/5.png";
// import Six from "../../Images/homeImages/6.png";
// import Eight from "../../Images/homeImages/8.png";
// import Nine from "../../Images/homeImages/9.png";
import culture from "../../Images/team/cultural day.jpg";
import studies from "../../Images/team/studies.jpg";
import { Container } from '../styles/common/Container.styled';
import { 
  FacilityDescription, 
  FacilityImageContainer, 
  SingleFacility, 
  SlantCard, 
  StyledFacilitiesSection, 
  Tags } from '../styles/sections/HomeSection.styled';
// import Admissions from "../../Images/news/Admissions.jpg";
// import WhatWeOffer from "../../Images/news/What-we-offer.jpg";



const News = () => {
  return (
    <>
    <Container>
    <SectionHeading style={{marginTop:'15rem'}}>  
        <h1>News</h1>
        <p>Stay in the know with our news board</p>
      </SectionHeading>
    </Container>
  <Container style={{marginTop:'-10rem'}}>
    <StyledFacilitiesSection>
    <SingleFacility>
                        <FacilityImageContainer>
                            <img src={studies} alt={'olivias nest'}/>
                            <SlantCard />
                        </FacilityImageContainer>
                        <FacilityDescription>
                        <h1 style={{fontWeight:'700'}}>Admissions and School Tour<hr /></h1>
                            <Tags>
                              <span style={{color:'white'}}>in-take</span>
                              <span style={{color:'white'}}>child-care</span>
                              <span style={{color:'white'}}>edu-care</span>
                            </Tags>
                            <p> Welcome to Olivia's Nest where we offer small class sizes, balanced teacher-learner ratios, 
                              a fun environment and learning through play. 
                              luxury preschool and daycare in kileleshwa/lavington. 25 Othaya Road, Nairobi, Kenya ... 
                              At Olivia's Nest our emphasis is to boost our little children's ability ...
                              </p>
                        </FacilityDescription>
                    </SingleFacility>
    </StyledFacilitiesSection>
    </Container>
    <Container>
    <StyledFacilitiesSection>
    <SingleFacility>
                        <FacilityImageContainer>
                            <img src={culture} alt={'olivias nest'}/>
                            <SlantCard />
                        </FacilityImageContainer>
                        <FacilityDescription>
                        <h1 style={{fontWeight:'700'}}>Admissions and School Tour<hr /> </h1>
                            <Tags>
                              <span style={{color:'white'}}>olivias nest</span>
                              <span style={{color:'white'}}>olivias nest</span>
                              <span style={{color:'white'}}>olivias nest</span>
                            </Tags>
                            <p> Welcome to Olivia's Nest where we offer small class sizes, balanced teacher-learner ratios, 
                              a fun environment and learning through play. 
                              luxury preschool and daycare in kileleshwa/lavington. 25 Othaya Road, Nairobi, Kenya ... 
                              At Olivia's Nest our emphasis is to boost our little children's ability ...
                              </p>
                        </FacilityDescription>
                    </SingleFacility>
    </StyledFacilitiesSection>
    </Container>

      <div className="wrapper">
  <SectionHeading style={{paddingTop:'2rem'}}>
    <h1>School Environs</h1>
  </SectionHeading>
  <div className="cols">

    <div className="col" ontouchstart="this.classList.toggle('hover');">
      <div className="container">
        <div className="front" style={{ backgroundImage: 'url("./images/landingPage/four.png")' }}>
          <div className="inner">
            <p>Swimming pool</p>
            <span>Swimming pool</span>
          </div>
        </div>
        <div className="back">
          <div className="inner">
            <p>
              We have swimming lessons and our pool clean and well maintained.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="col" ontouchstart="this.classList.toggle('hover');">
      <div className="container">
        <div className="front" style={{ backgroundImage: 'url("./images/landingPage/nine.png")'}}>
          <div className="inner">
            <p>Book reading area</p>
            <span>Book reading area</span>
          </div>
        </div>
        <div className="back">
          <div className="inner">
            <p>Our book reading area and story room is clean and well organized</p>
          </div>
        </div>
      </div>
    </div>

    <div className="col" ontouchstart="this.classList.toggle('hover');">
      <div className="container">
        <div className="front" style={{ backgroundImage: 'url("./images/landingPage/ten.png")' }}>
          <div className="inner">
            <p>Toy area</p>
            <span>toys</span>
          </div>
        </div>
        <div className="back">
          <div className="inner">
            <p>
              When it comes to toys and a space where our children play, it is sorted. 
              It's very spacious and clean as always with toys in great shape and operational.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="col" ontouchstart="this.classList.toggle('hover');">
      <div className="container">
        <div className="front" style={{ backgroundImage: 'url("./images/landingPage/eleven.png")' }}>
          <div className="inner">
            <p>Swing area</p>
            <span>out door activity</span>
          </div>
        </div>
        <div className="back">
          <div className="inner">
            <p>
              A swing area is where our children head over for a break and refreshment, it is a cool place to be.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <div></div>
    </>
    
  )
}

export default News




  
  
 
  

