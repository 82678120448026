import sleepingAreaImage from '../prod_images/FACILITIES/Sleeping Area/sleeping.png';
import classroom from '../prod_images/FACILITIES/Classrooms/classrooms.png';
// import piano from '../prod_images/FACILITIES/Piano/Piano.png';
// import chess from "../prod_images/FACILITIES/Chess/chess.png"
// import taekwondo from "../prod_images/FACILITIES/Taekwondo/taekwondo3.png";
import Elearning from "../prod_images/FACILITIES/e-Learning/E-learning.png";
import SwimmingPool from "../prod_images/FACILITIES/Swimming Pool/swimmingPool.png";
import Court from "../prod_images/FACILITIES/Tennis Court/tennis3.png";
export const IndoorFacilities = [
    {
        thumbnail: sleepingAreaImage,
        title: 'Sleeping area',
        tags: ["sleeping area", "facilities","olivia's nest"],
        paragraph: "We have one of the world-class sleeping areas which is comfy and very well suited for "+
        "kids to sleep on. The designs of the beds are custom-made and made of the best making sleeping hour more"+
        "rewarding to a child's overall performance and relaxation. A quality nap time reduces fatigue and so we chose"+
        "to make it one of a kind and enjoyable to our kids.  We have one of the world-class sleeping areas which is comfy and very well suited for "+
        "kids to sleep on. The designs of the beds are custom-made and made of the best making sleeping hour more"+
        "rewarding to a child's overall performance and relaxation. A quality nap time reduces fatigue and so we chose"+
        "to make it one of a kind and enjoyable to our kids." 
    },
    {
        thumbnail: classroom,
        title: "classrooms",
        tags: ["well-spaced", "fitting lockers","classrooms"],
        paragraph: "Our lockers are crafted to make our kids feel comfortable while learning. "+
        "During group discussions they can be conjoined together and make discussions easier and "+
        "can be attended to with ease.   Our lockers are crafted to make our kids feel comfortable while learning. "+
        "During group discussions they can be conjoined together and make discussions easier and "+
        "can be attended to with ease."
    },
    // {
    //     thumbnail: piano,
    //     title: "Piano classes",
    //     tags: ["music","keys","Piano"],
    //     paragraph: "Piano lessons play a crucial role in a child's early development,"+
    //     "nurturing their cognitive skills and creativity."+
    //     "Our dedicated piano room features a variety of age-appropriate instruments designed to make learning engaging and fun for all young learners."+
    //      "We offer a supportive and stimulating environment where each child can explore music at their own pace,"+
    //       "guided by experienced educators who specialize in teaching kindergarten students."+
    //       "Rest assured, your child will receive personalized attention and encouragement in a safe and nurturing setting."
    // },
    // {
    //     thumbnail: chess,
    //     title: "Chess coaching",
    //     tags: ["cognitive skills","critical thinking","brainstorming"],
    //     paragraph: "Chess is a wonderful way to enhance a child's cognitive development, critical thinking,"+
    //     "and problem-solving skills. Our dedicated chess room is equipped with child-friendly chess sets and teaching tools designed to"+
    //     "make learning the game both fun and effective for young minds."+
    //     "We offer specialized coaching for kindergarten children, focusing on age-appropriate strategies"+
    //     "and activities that foster a love for chess while building essential skills. Rest assured,"+
    //     "your child will be guided by experienced instructors in a supportive and secure environment,"+
    //     "making their chess learning experience enjoyable and enriching."
    // },
    // {
    //     thumbnail: taekwondo,
    //     title: "Taekwondo",
    //     tags: ["KidsTaekwondo","TaekwondoFun","MartialArtsForChildren"],
    //     paragraph: "Taekwondo is an excellent way for young children to develop physical coordination, discipline, and confidence."+ 
    //     "Our dedicated Taekwondo studio features age-appropriate equipment and a curriculum designed to introduce kindergarten" +
    //     "children to the fundamentals of martial arts in a fun and supportive environment. Our experienced instructors use engaging techniques"+
    //     "to teach basic Taekwondo skills while promoting respect, focus, and teamwork. You can be confident that your child will receive personalized"+
    //     "attention and encouragement, all within a safe and nurturing atmosphere."
    // },  
    
    {
        thumbnail: Elearning,
        title: "E-learning",
        tags: ["E-learning","amenities","indoor"],
        paragraph: "E-learning is an integral part of modern education,"+
        "revolutionizing how knowledge is imparted. We embrace digital tools and online platforms"+ 
        "to provide flexible and interactive learning experiences. E-learning offers a diverse range of courses"+
        "and materials, catering to various learning styles and needs. We integrate advanced technology to"+
        "enhance learning and make education accessible from anywhere. E-learning is an integral part of"+
        "modern education, revolutionizing how knowledge is imparted. We embrace digital tools and online"+
        "platforms to provide flexible and interactive learning experiences."
    }, 
    {
        thumbnail: Court,
        title: "Court",
        tags: ["Tennis court","skating area","basketball court"],
        paragraph: "At Olivia's Nest, our court is a dynamic space where energy and learning intersect, offering children the chance"+
        "to engage in skating, basketball, and tennis. This vibrant area encourages physical development, teamwork, and discipline, while also"+
        "fostering a love for sports and an active lifestyle. Whether they're gliding on skates, shooting hoops, or serving on the tennis court,"+
        "our students are not just playing—they're building confidence, resilience, and a strong sense of community, all essential elements in their"+
        "growth and education."
    },
    {
        thumbnail: SwimmingPool,
        title: "Swimming Pool",
        tags: ["Swimming lessons","swimming pool"],
        paragraph: "At Olivia's Nest, our swimming pool is more than just a place to swim—it's a vibrant space where education"+
        "and play come together to nurture young minds and bodies. Just as water sustains life, our pool fosters confidence,"+
        "teamwork, and physical health, providing children with the opportunity to explore, learn, and grow in a fun and supportive"+
        "environment. Every splash in our pool is a step towards discovery, making waves in their educational journey and planting the seeds of lifelong skills."
    },
   
];